import { Box, Icon, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineGlobal } from 'react-icons/ai';
import { BsCheck2 } from 'react-icons/bs';
import { GiRoad } from 'react-icons/gi';

// GrUserExpert
const BodyBottom = () => {
  return (
    <Stack direction={{ base: 'column', lg: 'row' }} pt={10}>
      <CustomBox
        title="Efficient Logistics"
        icon={GiRoad}
        body="When you’re focusing on the day-to-day of growing your business, it
        can be hard to find the time to navigate the complexities of the
        logistics industry and make sure your solutions are as efficient as
        they could be."
      />

      <CustomBox
        title="Expert support"
        icon={AiOutlineGlobal}
        body=" Ever since our beginning, one of our core aims has been to make
        everything more manageable and less stressful for business owners and
        logistics managers looking to scale up or improve their logistics."
      />
      <CustomBox
        title="Scalable solutions"
        icon={BsCheck2}
        body="If you already know what you want, we’ll make it happen. But if you
          don’t know where to start, we’ll listen to your needs, take the time
          to understand your business, explain everything, and then we’ll
          deliver what we say we will."
      />
    </Stack>
  );
};

const CustomBox = ({ title, body, icon }) => {
  return (
    <Box
      borderWidth={1}
      minH="250px"
      px={1}
      borderRadius={8}
      shadow="md"
      _hover={{ shadow: 'xl' }}
      textAlign={'center'}
      w={{ base: '100%', lg: '33%' }}
    >
      <Icon
        borderWidth={2}
        borderColor="blue.400"
        p={2}
        mt={3}
        as={icon}
        boxSize="10"
        border
        borderRadius={20}
      />
      <Text fontWeight="bold" color="blue.500" fontSize={'xl'}>
        {title}
      </Text>
      <Text lineHeight={8}>{body}</Text>
    </Box>
  );
};

export default BodyBottom;
