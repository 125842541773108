import { ChakraProvider, theme, VStack } from '@chakra-ui/react';
import React from 'react';
import BodyBottom from './BodyBottom';
import BodyTop from './BodyTop';
import Footer from './Footer';
import Header from './Header';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <VStack bg="whiteAlpha.100" mx={10} my={4}>
        <Header />
        <BodyTop />
        <BodyBottom />
        <Footer />
      </VStack>
    </ChakraProvider>
  );
}

export default App;
