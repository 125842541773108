import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import IconGray from './icon-gray.png';

const Footer = () => {
  return (
    <Stack direction={{ base: 'column', lg: 'row' }} w="full" pt={10}>
      {/* Stackda margin ishlemir */}
      <Box w={{ base: '100%', lg: '70%' }}>
        <Box mb={2}>
          <Text color="gray.900" fontWeight={'semibold'}>
            UNICOM TRADE DANISMANLIK HIZMETLERI ITHALAT VE IHRACAT TICARET
            LIMITED
          </Text>
        </Box>
        <Box mb={2}>
          <Text display="inline" fontWeight={'semibold'}>
            Address:
          </Text>
          <Text ml={2} display="inline" color="gray.600" fontWeight={'medium'}>
            Molla Feneri Mah. Nur-u Osmaniye Cad. Orient Bazaar No:45 / 401
            Fatih / İSTANBUL
          </Text>
        </Box>
        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Box>
            <Text display="inline" fontWeight={'semibold'}>
              Telehone:
            </Text>
            <Text
              ml={2}
              display="inline"
              color="gray.600"
              fontWeight={'medium'}
            >
              +90 530 790 17 88
            </Text>
          </Box>
        </Stack>
      </Box>
      <Flex
        w={{ base: '100%', lg: '40%' }}
        alignSelf={'flex-end'}
        justifyContent={'end'}
      >
        <Image display="inline" h={5} mt={2} src={IconGray} />

        <Text
          display="inline"
          color="gray.400"
          fontWeight={'bold'}
          fontSize="2xl"
        >
          UNICOM TRADE
        </Text>
      </Flex>
    </Stack>
  );
};

export default Footer;
