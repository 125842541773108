import { Box, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import Image1 from './image-1.png';
import Image2 from './image-2.jpeg';
import Image3 from './image-3.jpeg';
import Image4 from './image-4.jpeg';

const BodyTop = () => {
  return (
    <Box pt={5}>
      <Stack direction={{ base: 'column', lg: 'row' }}>
        <CustomerImageBox image={Image1} title="Shipping & Logistics" />
        <CustomerImageBox image={Image2} title="Construction Materials" />
      </Stack>
      <Stack direction={{ base: 'column', lg: 'row' }}>
        <CustomerImageBox image={Image3} title="Chemical Products" />
        <CustomerImageBox image={Image4} title="Food Supplies" />
      </Stack>
    </Box>
  );
};

const CustomerImageBox = ({ image, title }) => {
  return (
    <Box>
      <Image
        borderRadius={8}
        shadow="md"
        _hover={{ shadow: 'xl' }}
        src={image}
        w="500px"
        // m={6}
        h="320px"
        display="inline"
      />
      <Text
        textAlign={'center'}
        fontWeight="bold"
        fontSize="2xl"
        color="blue.400"
      >
        {title}
      </Text>
    </Box>
  );
};

export default BodyTop;
